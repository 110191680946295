import http from "./http.js";

export default {
  postTarif(payload) {
    return http.post(`/mixed`, { payload });
  },
  directLogin(payload) {
    return http.get(`/startlogin/${payload.token}`);
  },
  getUser(payload) {
    return http.get(`/getuser/${payload.action}/0`);
  },
  postUser(payload) {
    return http.post("/postuser", { payload });
  },
  postUserLogged(payload) {
    return http.post("/postuserlogged", { payload });
  },
  getBestellung(payload) {
    return http.get(`/getbestellung/${payload.action}/0`);
  },
  getBestellungPost(payload) {
    return http.post(`/getbestellungpost`, { payload });
  },
  postWebinar(payload) {
    return http.post(`/webinar/postwebinar`, { payload });
  },
  postWebinarUser(payload) {
    return http.post(`/webinar/postwebinaruser`, { payload });
  },
  postBestellungLogged(payload) {
    return http.post("/postbestellunglogged", { payload });
  },
  postTestAdmin(payload) {
    return http.post("/testpost", { payload });
  },
  // Weitere Benutzer-bezogene API-Aufrufe hier
};
