<template>
  <div class="ml-0 my-4">
    <v-card elevation="1" >
      <v-card-text>
        <v-row v-if="showClose"
          ><v-col cols="10"></v-col
          ><v-col cols="2"
            ><v-btn icon @click="userStore.closeLogin">
              <v-icon>mdi-close</v-icon>
            </v-btn></v-col
          ></v-row
        >
        <div v-if="!showPassVergessen">
          <h2 class="mb-2">Team<span class="text-blue">Waizmann</span>  |  Login</h2>
          Melden Sie sich einfach mit Ihren <b>WaizmannTabelle</b> Zugangsdaten
          an. Wenn Sie Ihr Passwort vergessen haben, klicken Sie bitte auf
          "Passwort vergessen".<br /><br />
          <v-form @submit.prevent="submitForm">
            <v-text-field
              v-model="userLogin.user_email"
              label="E-Mail"
              type="email"
              required
            ></v-text-field>

            <v-text-field
              v-model="userLogin.user_pass"
              label="Passwort"
              :type="showPassword ? 'text' : 'password'"
              append-icon="mdi-eye"
              @click:append="toggleShowPassword"
              required
            ></v-text-field>

            <v-btn type="submit" color="primary"
              >TeamWaizmann | WaizmannTabelle Login</v-btn
            >
            <v-btn
              class="ml-12"
              @click="showPassVergessen = !showPassVergessen"
              type="submit"
              color="primary"
              >Passwort vergessen</v-btn
            >
          </v-form>
        </div>
        <div v-if="showPassVergessen">
          <h2>Passwort zusenden</h2>
          <v-form @submit.prevent="submitFormVergessen">
            <v-text-field
              v-model="userLogin.user_email"
              label="E-Mail"
              type="email"
              style="font-size: 18px;"
              required
            ></v-text-field>

            <v-btn type="submit" color="primary"
              >Passwort per E-Mail zusenden</v-btn
            >
            <v-btn
              class="ml-4"
              type="submit"
              color="primary"
              style="font-size: 18px;"
              @click="showPassVergessen = !showPassVergessen"
              >zurück zum Login</v-btn
            >
          </v-form>
        </div>
      </v-card-text>
    </v-card>
  </div>
</template>

<script setup>
  import { reactive, ref,defineProps } from "vue";
  import BaseCalls from "@/services/BaseCalls";
  import { useUserStore } from "@/stores/user";
  import { useErrorStore } from "@/stores/errorStore";
  import { useSuccessStore } from "@/stores/successStore";

  import { useRouter } from "vue-router";

  const router = useRouter();
  const userStore = useUserStore();
  const errorStore = useErrorStore();
  const successStore = useSuccessStore();
  const showPassVergessen = ref(false);

  const showPassword = ref(false); // Zustand der Sichtbarkeit des Passworts

  const props = defineProps({
    showClose: {
      type: String,
      default: "",
    },
  });
  console.log(props.showClose);
  // Methode zum Umschalten der Sichtbarkeit des Passworts
  const toggleShowPassword = () => {
    showPassword.value = !showPassword.value;
  };

  const userLogin = reactive({
    user_pass: "",
    user_email: "",
    user_action: "startLogin",
  });

  const submitForm = async () => {
    userLogin.user_action = "startLogin";

    try {
      const resp = await BaseCalls.postUser(userLogin);
      if (resp.data && resp.data.user) {
        userStore.setUser(resp.data.user);
        userStore.isLogged = true;
        userStore.showLogin = false;
        successStore.setSuccess(
          true,
          "Login erfolgreich",
          "Sie sind jetzt eingeloggt"
        );
        router.push("/waizmanntabellen_bestellen");
      } else {
        errorStore.setError(true, "Beim Anmelden ist ein Fehler aufgetreten.");
      }
    } catch (err) {
      errorStore.setError(true, "Beim Anmelden ist ein Fehler aufgetreten.");
    }
  };

  const submitFormVergessen = async () => {
    userLogin.user_action = "resendPassword";
    try {
      const resp = await BaseCalls.postUser(userLogin);
      if (resp.data && resp.data.success) {
        showPassVergessen.value = false;
        userStore.showLogin = false; // Direkte Zuweisung, wenn showLogin reaktiv im Store definiert ist
        successStore.setSuccess(
          true,
          "Passwort versendet",
          "Ihr Passwort wurde Ihnen zugesendet"
        );
      } else {
        errorStore.setError(
          true,
          "Das Passwort konnte nicht zugesendet werden"
        );
      }
    } catch (err) {
      errorStore.setError(true, "Das Passwort konnte nicht zugesendet werden");
    }
  };
</script>
