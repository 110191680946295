<template>
  <div>
   <v-row class="ml-12" style="max-width: 999px;">
 
 <v-col  style="border-right: 2px solid #E5EDF4;">
   <span class="head20"><span class="text-blue">Webinar </span>Profit Booster PZR</span><br>
   <div class="mt-4 head18">6.11.2024 19:00 Uhr</div>
   <div class="mt-4 head18">13.11.2024 19:00 Uhr</div>
   <div  class="mt-4 head18"><router-link to="/webinare/webinar_profit_booster_professionelle_zahnreinigung"><b>an Webinar teilnehmen</b></router-link></div>

 </v-col>
 <v-col class="pl-8"  style="border-right: 2px solid #E5EDF4;" >
  <span class="head20"><span class="text-blue">Webinar </span>Bye,Bye Bema</span><br>
 
   <div  class="mt-4 head18">16.6.2024 19:00 Uhr | abgeschlossen</div>
   <div  class="mt-4 head18">3.7.2024 19:00 Uhr | abgeschlossen</div>
 </v-col>

 <v-col class="pl-8"  style="border-right: 2px solid #E5EDF4;" >
  <span class="head20">Neue Webinar-Teilnehmer</span><br>
  <span v-for="t in teilnehmer" :key="t.wteil_id">
          <span v-if="t.wteil_pers_type <= 1">Zahnarzt </span
          ><span v-if="t.wteil_pers_type == 2">ZFA </span>{{ t.user_ort }}
          |
        </span>
  
 </v-col>
 
 </v-row>
  </div>
 </template>
 
 <script setup>
   import {ref,onMounted } from "vue";
   import BaseCalls from "@/services/BaseCalls";
  const  teilnehmer = ref(false);
  
  const getTeilnehmer = async () => {
    console.log("get Teilnehmer");
    try {
      const data = {
        action: "getTeilnehmerNeue",
      };
      const resp = await BaseCalls.postWebinar(data);
      if (resp.data && resp.data.teilnehmer) {
        teilnehmer.value = resp.data.teilnehmer;
       

        // setLoading(false); // Diese Zeile kommentiert, da setLoading nicht definiert ist
      }
    } catch (err) {
      console.log("Fehler bei der Aktion:", err.message);
    }
  };
  onMounted(async () => {
    await getTeilnehmer();
  });
 </script>
 