import { defineStore } from "pinia";

export const useSuccessStore = defineStore("successStore", {
  state: () => ({
    load: false, // Initialisiert mit false, um das Laden nicht sofort anzuzeigen
    title: "Alle klar!", // Standardfehlermeldung
    message: "Ihre Anfrage war erfolreich", // Standardfehlermeldung
  }),
  actions: {
    setSuccess(load, title = "", message = "") {
      this.load = load;
      // Verwende die übergebene Nachricht, falls vorhanden, sonst behalte die aktuelle Nachricht bei
      if (message) this.message = message;
      if (title) this.title = title;
    },
    clearSuccess() {
      this.load = false; // Setzt `load` auf false statt auf null
      this.title = "Alle klar!";
      this.message = "Ihre Anfrage war erfolreich."; // Setze die Nachricht zurück auf den Standardwert
    },
  },
});
