<template>
  <div style="height: 1000px">
    <HomeTeaser />
    <v-row class="my-12"  style="background-color: #E5EDF4;" v-if="!userStore.isLogged">
      <v-col cols="12" md="9">
      <RegisterUser v-if="!user" />
      </v-col>
      <v-col cols="12" md="3"  >
        <div  class="pa-4">
        <h2>Gerade angefordert</h2>
            <span v-for="bestellung in bestellungen" :key="bestellung.bestell_id">
              <span class="mr-6"> <b class="text-green head18">Zahnarztpraxis {{ bestellung.user_ort }}</b> WaizmannTabelle:  {{ sharedDataStore.getProduktText(bestellung.bestell_produkt_text) }}  ({{ sharedDataStore.getTimeAgo(bestellung.earliest_created_date) }}) </span>
             
            </span>
          </div>
      </v-col>
    </v-row>
    <v-row class="mt-12" style="background-color: #E5EDF4;">
      <v-col cols="12" md="4" class="pa-6">
        <v-card elevation="0" class="px-4 pt-4">
          <v-row >
            <v-col cols="12" style="height: 420px;">
             <v-row style="border-bottom: 4px solid #E5EDF4;">
              <v-col cols="9"><h3>WaizmannTabelle</h3><h2>PZR-Flatrate | <span class="text-green">Neu</span></h2></v-col>
              <v-col cols="3"><v-icon style="font-size: 36px;">mdi-table-arrow-right</v-icon></v-col>
             </v-row>
             <v-row class="mt-4" style="border-bottom: 4px solid #E5EDF4;">
              <v-col cols="6">
                <img
          :src="require('@/assets/bestellung/pzrPicto.jpg')"
          style="width: 160px; float: left"
        />
              </v-col>
              <v-col cols="6" class="pl-4">Fordern Sie jetzt kostenlos die neue WaizmannTabelle | <b>PZR-Flatrate</b> für Ihr Prophylaxe-Team an. 
              </v-col>  
            </v-row>
            <v-row class="mt-4">
              <v-col cols="12">
                <router-link to="/login/waizmanntabellen" class="noUnderline head18">
                  <b>jetzt kostenlos bestellen</b>
                </router-link>
              </v-col>
            </v-row>
              </v-col>
          </v-row>
        </v-card>
      </v-col>
      <v-col cols="12" md="4" class="pa-6">
        <v-card elevation="0" class="px-4 pt-4">
          <v-row >
            <v-col cols="12" style="height: 420px;">
             <v-row style="border-bottom: 4px solid #E5EDF4;">
              <v-col cols="9"><h3>WaizmannWebinar</h3><h2>Profit Booster PZR</h2></v-col>
              <v-col cols="3"><v-icon style="font-size: 36px;">mdi-school</v-icon></v-col>
             </v-row>
             <v-row class="mt-4" style="border-bottom: 4px solid #E5EDF4;">
              <v-col cols="6">
                <img style="width: 160px" :src="require('@/assets/site/zacoach.jpg')" />
                <ul  v-if="termine.length > 0">
        <li v-for="t in termine.slice(0, 3)" :key="t.wter_id">
          <span v-if="t.wter_status == 0">{{ t.datum }}</span>
          <span class="text-red" v-if="t.wter_status == 1"
            >{{ t.datum }} <br />bereits beendet</span
          >
        </li>
        <li><router-link to="/webinare/webinar_profit_booster_professionelle_zahnreinigung"
            >Jetzt anmelden</router-link
          ></li>

      
      </ul>
              </v-col>
              <v-col cols="6" class="pl-4">  <b>Dipl.-Psych. Frank Frenzel</b>
        <ul>
          <li>Führender Zahnarzt-Coach in Deutschland</li>
          <li>
            Betreiber des Nr. 1 Youtube-Kanals für Praxis-Management und
            -Kommunikation: @MeinZahnarztCoach
          </li>
          <li>
            <router-link to="/webinare/webinar_profit_booster_professionelle_zahnreinigung"
              >... mehr dazu....</router-link
            >
          </li>
        </ul>      </v-col>  
            </v-row>
            <v-row class="mt-4">
              <v-col cols="12">
                <router-link to="/login/waizmanntabellen" class="noUnderline head18">
                  <b>jetzt kostenlos bestellen</b>
                </router-link>
              </v-col>
            </v-row>
              </v-col>
          </v-row>
        </v-card>
      </v-col>

      <v-col cols="12" md="4" class="pa-6">
        <v-card elevation="0" class="px-4 pt-4">
          <v-row >
            <v-col cols="12" style="height: 420px;">
             <v-row style="border-bottom: 4px solid #E5EDF4;">
              <v-col cols="9"><h3>WaizmannWebinar</h3><h2>Neue Anmeldungen</h2></v-col>
              <v-col cols="3"><v-icon style="font-size: 36px;">mdi-account</v-icon></v-col>
             </v-row>
        <v-row>
          <v-col cols="12">
            <span v-for="t in teilnehmer" :key="t.wteil_id">
              <span v-if="t.wteil_pers_type <= 1">Zahnarzt </span
              ><span v-if="t.wteil_pers_type == 2">ZFA </span>{{ t.user_ort }}
              |
            </span>
          </v-col>
        </v-row>
          
              </v-col>
          </v-row>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script setup>
  import { getCurrentInstance, ref, computed, onMounted } from "vue";
  import RegisterUser from "./../components/user/RegisterUser.vue";
  import BaseCalls from "@/services/BaseCalls";
  import HomeTeaser from "@/views/helper/HomeTeaser.vue";
  import { useUserStore } from "@/stores/user";
 
  import { useSharedDataStore } from "@/stores/sharedData";
  const sharedDataStore = useSharedDataStore();
  const userStore = useUserStore();
  const user = computed(() => userStore.userData);
  const bestellungen = ref(false);

  const termine = ref([]);
  const getTermine = async () => {
    try {
      const data = { wter_webinar_id: 2, action: "getWebinarTerminePerId" };
      const resp = await BaseCalls.postWebinar(data);
      if (resp.data && resp.data.termine) {
        termine.value = resp.data.termine;
        // setLoading(false); // Diese Zeile kommentiert, da setLoading nicht definiert ist
      }
    } catch (err) {
      console.log("Fehler bei der Aktion:", err.message);
    }
  };
  const  teilnehmer = ref(false);
  
  const getTeilnehmer = async () => {
    console.log("get Teilnehmer");
    try {
      const data = {
        action: "getTeilnehmerNeue20",
      };
      const resp = await BaseCalls.postWebinar(data);
      if (resp.data && resp.data.teilnehmer) {
        teilnehmer.value = resp.data.teilnehmer;
       

        // setLoading(false); // Diese Zeile kommentiert, da setLoading nicht definiert ist
      }
    } catch (err) {
      console.log("Fehler bei der Aktion:", err.message);
    }
  };
  
  // Funktion zum Abrufen aller Benutzerbestellungen
  async function fetchAlleUserBestellungen() {

      try {
        const data = {
          action: "getBestellungen",
          select: "getShowBestellungen",
          limit: 8
        };
        const resp = await BaseCalls.getBestellungPost(data);
        if (resp.data && resp.data.bestellungen) {
          bestellungen.value = resp.data.bestellungen;
        
        }
      } catch (err) {
        console.log("Fehler bei der Aktion:", err.message);
      }
    }
  
  onMounted(() => {
    const { proxy } = getCurrentInstance();
    proxy.$updateDocumentHead(
      "TeamWaizmann",
      "Service Zahnzusatzversicherung für Zahnärzte"
    );
    getTeilnehmer();
    fetchAlleUserBestellungen();
    getTermine();
  });

</script>
