<template>
  <v-container>
    <div class="pa-2">
      <h2>Jetzt neu</h2>
      Fordern Sie jetzt kostenlos die neue WaizmannTabelle
      <b>PZR-Flatrate</b> für Ihr Prophylaxe-Team an. Kostenlos.
      <a href="https://www.teamwaizmann.de" target="_blank">Jetzt anfordern</a>
      <br /><br />

      <img
        :src="require('@/assets/site/togoPzr.png')"
        style="width: 140px; float: left;margin-bottom: 40px;"
      />
    </div>

      <div class="pa-2">
     
   
      <h2><span class="text-blue">Webinar:</span> Profit Booster Professionelle Zahnreinigung | kostenlos</h2>

      <div class="my-2">
        <img style="width: 230px" :src="require('@/assets/site/zacoach.jpg')" />
      </div>
      <div>
        <b>Dipl.-Psych. Frank Frenzel</b>
        <ul>
          <li>Führender Zahnarzt-Coach in Deutschland</li>
          <li>
            Betreiber des Nr. 1 Youtube-Kanals für Praxis-Management und
            -Kommunikation: @MeinZahnarztCoach
          </li>
          <li>
            <router-link to="/webinare/webinar_profit_booster_professionelle_zahnreinigung"
              >... mehr dazu....</router-link
            >
          </li>
        </ul>
      </div>
      <div class="my-2">
      <br /><br />
      <h2><span class="text-blue">Die nächste Termine</span></h2>

      <ul  v-if="termine.length > 0">
        <li v-for="t in termine" :key="t.wter_id">
          <span v-if="t.wter_status == 0">{{ t.datum }}</span>
          <span class="text-red" v-if="t.wter_status == 1"
            >{{ t.datum }} <br />bereits beendet</span
          >
        </li>
        <li><router-link to="/webinare/webinar_profit_booster_professionelle_zahnreinigung"
            >Jetzt anmelden</router-link
          ></li>

      
      </ul>
</div>
   
    </div>
  </v-container>
</template>

<script setup>
  import {ref,onMounted} from "vue";
  import BaseCalls from "@/services/BaseCalls";
  const termine = ref([]);
  const getTermine = async () => {
    try {
      const data = { wter_webinar_id: 2, action: "getWebinarTerminePerId" };
      const resp = await BaseCalls.postWebinar(data);
      if (resp.data && resp.data.termine) {
        termine.value = resp.data.termine;
        // setLoading(false); // Diese Zeile kommentiert, da setLoading nicht definiert ist
      }
    } catch (err) {
      console.log("Fehler bei der Aktion:", err.message);
    }
  };

  onMounted(async () => {
    await getTermine();
  });
</script>
