<template>
 <div>
  <v-row class="my-12">
      <v-col cols="12" md="3" class="pr-6"  style="border-right: 2px solid #E5EDF4;text-align: right;">
       <span style="font-size:32px;font-weight: bold;">TeamWaizmann</span><br>
       <span style="font-size:32px;">ZZV-Service für <br>Zahnärzte</span>
      </v-col>
      <v-col cols="12" md="3" class="pl-6" style="border-right: 2px solid #E5EDF4; display: flex; flex-direction: column; justify-content: flex-end;">
            <v-icon class="text-blue" style="font-size: 56px; align-self: flex-start;">mdi-table-arrow-right</v-icon>
            <span style="align-self: flex-end;"><b>kostenlos</b> WaizmannTabellen anfordern</span>
      </v-col>
      <v-col cols="12" md="3" class="pl-6" style="border-right: 2px solid #E5EDF4; display: flex; flex-direction: column; justify-content: flex-end;">
            <v-icon class="text-blue" style="font-size: 56px; align-self: flex-start;">mdi-school</v-icon>
            <span style="align-self: flex-end;"><b>kostenlos</b> an WaizmannWebinaren teilnehmen</span>
      </v-col>
      <v-col cols="12" md="3" class="pl-6" style="border-right: 2px solid #E5EDF4; display: flex; flex-direction: column; justify-content: flex-end;">
            <v-icon class="text-blue" style="font-size: 56px; align-self: flex-start;">mdi-download</v-icon>
            <span style="align-self: flex-end;"><b>kostenlos</b>  ZZV-Informationen downloaden</span>
      </v-col>
    </v-row>
 </div>
</template>

<script setup>

</script>
