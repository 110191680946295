<template>
  <div>
   <v-row class="ml-12" style="max-width: 999px;">
 
 <v-col  style="border-right: 2px solid #E5EDF4;">
   <span class="head20"><span class="text-blue">PraxisWissen </span>ZZV & Prophylaxe</span><br>
   <div class="mt-4 head18">ZZV-PraxisWissen PZR für Kinder</div>
   <div class="mt-4 head18">ZZV-PraxisWissen Professionelle Zahnreinigung</div>
   <div class="mt-4 head18">Praxisumfrage 2024 Prophylaxe - Ergebnisse</div>
   <div  class="mt-4 head18"><router-link to="/prophylaxe"><b>alle Infos anzeigen</b></router-link></div>
 </v-col>
 <v-col  style="border-right: 2px solid #E5EDF4;">
  </v-col>

 
 </v-row>
  </div>
 </template>
 
 <script setup>
 
 </script>
 