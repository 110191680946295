<template>
  <v-container>
    <h2>TeamWaizmann starten</h2>
  </v-container>
</template>

<script setup>
  import { reactive } from "vue";
  import BaseCalls from "@/services/BaseCalls";
  import { useUserStore } from "@/stores/user";
  import { useErrorStore } from "@/stores/errorStore";
  import { useSuccessStore } from "@/stores/successStore";
  import { useRoute, useRouter } from "vue-router";

  const route = useRoute();
  const router = useRouter();
  const userStore = useUserStore();
  const errorStore = useErrorStore();
  const successStore = useSuccessStore();

  const userLogin = reactive({
    token: route.params.token,
  });

  const submitForm = async () => {
    try {
      console.log("sumitform");
      const resp = await BaseCalls.directLogin(userLogin);
      if (resp.data && resp.data.user) {
        userStore.setUser(resp.data.user);
        userStore.isLogged = true;
        userStore.showLogin = false;
        successStore.setSuccess(
          true,
          "Login erfolgreich",
          "Sie sind jetzt eingeloggt"
        );
        router.push("/");
      } else {
        errorStore.setError(true, "Beim Anmelden ist ein Fehler aufgetreten.");
      }
    } catch (err) {
      errorStore.setError(true, "Beim Anmelden ist ein Fehler aufgetreten.");
    }
  };
  if (userLogin.token != "") {
    submitForm();
  }
</script>
