const baseplugin = {
  install(app) {
    app.config.globalProperties.$updateDocumentHead = (
      siteTitle,
      description
    ) => {
      document.title = siteTitle;
      const metaDescription = document.querySelector(
        'meta[name="description"]'
      );
      if (metaDescription) {
        metaDescription.setAttribute("content", description);
      }
    };

    app.config.globalProperties.$logError = (error) => {
      console.error("LogError: ", error);
    };

    // Weitere Funktionen hier hinzufügen
  },
};

export default baseplugin;
