<template>
  <div v-if="user.user_id" class="mt-4">
    <h3 class="text-green">Für Zahnärzte kostenlos</h3>
    <h2 v-if="user.user_kind_only == 0">
      Jetzt WaizmannTabellen
      <span class="text-green">2024</span> anfordern
    </h2>

    <h2 v-if="user.user_kind_only == 1">
      Jetzt WaizmannTabellen KFO/Kinder
      <span class="text-green">2024</span> anfordern
    </h2>

    <v-row class="my-4">
      <v-col :cols="1" :md="1" class="my-0 py-0 align-self-start">
        <v-checkbox
          class="ma-0"
          @click="addOderEntferneProdukt('Waizmanntabelle ToGo', 'tabelle')"
        ></v-checkbox>
      </v-col>
      <v-col :cols="11" :md="8">
        <h3>
          <span style="color: #2196f3">
            WaizmannTabelle ToGo | Erwachsene & Kinder
            <sup class="text-green">Tipp</sup>
          </span>
          | zur Mitnahme
        </h3>
        T0P 10 Tarife Erwachsene & Kinder - im praktischen Postkartenformat. Zur
        Mitnahme. Ideal in Kombination mit WaizmannTabelle
        Acrylglas-Aufsteller.
      </v-col>
      <v-col :cols="12" :md="3" v-if="sharedDataStore.isDesktop()">
        <img
          :src="require('@/assets/bestellung/ePicto.jpg')"
          style="width: 60px; float: left"
        />
      </v-col>
    </v-row>

    <v-row class="my-4">
      <v-col :cols="1" :md="1" class="my-0 py-0 align-self-start">
        <v-checkbox
          class="ma-0"
          @click="addOderEntferneProdukt('Waizmanntabelle PZR', 'tabelle')"
        ></v-checkbox>
      </v-col>
      <v-col :cols="11" :md="8">
        <h3>
          <span style="color: #2196f3">
            WaizmannTabelle | PZR-Flatrate
            <sup class="text-green">Neu</sup>
          </span>
          | für Ihr Prophylaxe-Team
        </h3>
        T0P 10 der PZR-Flatrate Tarife. Diese Tarife leisten sowohl für
        Zahnersatz & Zahnerhalt als auch in unbegrenzter Höhe und Anzahl für
        die professionelle Zahnreinigung. Auch für mehr als 2 PZR-Behandlungen
        pro Jahr.
      </v-col>
      <v-col :cols="12" :md="3" v-if="sharedDataStore.isDesktop()">
        <img
          :src="require('@/assets/bestellung/pzrPicto.jpg')"
          style="width: 60px; float: left"
        />
      </v-col>
    </v-row>

    <v-row class="my-4">
      <v-col :cols="1" :md="1" class="my-0 py-0 align-self-start">
        <v-checkbox
          class="ma-0"
          @click="addOderEntferneProdukt('Aufsteller ', 'tabelle')"
        ></v-checkbox>
      </v-col>
      <v-col :cols="11" :md="8">
        <h3>
          <span style="color: #2196f3">
            Transparenter Acrylglas-Aufsteller für ToGo Tabellen
          </span>
          | zur Mitnahme
        </h3>
        Damit können Sie die ToGo Tabellen in Ihrer Praxis zur Mitnahme
        anbieten. Wenn Sie mehrere Aufsteller (kostenlos) benötigen, fordern
        Sie diesen einfach mehrfach an.
      </v-col>
      <v-col :cols="12" :md="3" v-if="sharedDataStore.isDesktop()">
        <img
          :src="require('@/assets/site/aufsteller.jpg')"
          style="width: 60px; float: left"
        />
      </v-col>
    </v-row>

    <v-row class="my-4">
      <v-col :cols="1" :md="1" class="my-0 py-0 align-self-start">
        <v-checkbox
          class="ma-0"
          @click="addOderEntferneProdukt('Waizmanntabelle Gesamtausgabe ', 'tabelle')"
        ></v-checkbox>
      </v-col>
      <v-col :cols="11" :md="8">
        <h3>
          <span style="color: #2196f3">
            WaizmannTabelle Gesamtausgabe 2024
          </span>
          | für Ihr Wartezimmer
        </h3>
        Mit allen von WaizmannTabelle getesteten Zahnzusatzversicherungen,
        Hintergründen zur PZR-Flatrate und Anleitung zum Wechsel in
        abrechnungsstarke Zahnzusatzversicherungen. Für den Lesebereich Ihres
        Wartezimmers.
      </v-col>
      <v-col :cols="12" :md="3" v-if="sharedDataStore.isDesktop()">
        <img
          :src="require('@/assets/site/gesamt.jpg')"
          style="width: 60px; float: left"
        />
      </v-col>
    </v-row>

    <v-row class="my-4" v-if="user.user_kind_only == 0">
      <v-col :cols="1" :md="1" class="my-0 py-0  align-self-start">
        <v-checkbox
          class="ma-0"
          @click="addOderEntferneProdukt('Waizmanntabelle XXL Erwachsene ', 'tabelle')"
        ></v-checkbox>
      </v-col>
      <v-col :cols="11" :md="8">
        <h3>
          <span style="color: #2196f3">
            WaizmannTabelle XXL Erwachsene
          </span>
          | für Ihre Praxisräume
        </h3>
        TOP 100 Tarife Erwachsene. Informationsposter für Ihre Praxisräume.
      </v-col>
      <v-col :cols="12" :md="3" v-if="sharedDataStore.isDesktop()">
        <img
          :src="require('@/assets/bestellung/xxlPicto.jpg')"
          style="width: 60px; float: left"
        />
      </v-col>
    </v-row>

    <v-row class="my-4">
      <v-col :cols="1" :md="1" class="my-0 py-0 align-self-start">
        <v-checkbox
          class="ma-0"
          @click="addOderEntferneProdukt('Waizmanntabelle XXL Kinder ', 'tabelle')"
        ></v-checkbox>
      </v-col>
      <v-col :cols="11" :md="8">
        <h3>
          <span style="color: #fecf0c">
            WaizmannTabelle XXL Kinder
          </span>
          | für Ihre Praxisräume
        </h3>
        TOP 50 Tarife Kinder. Informationsposter für Ihre Praxisräume.
      </v-col>
      <v-col :cols="12" :md="3" v-if="sharedDataStore.isDesktop()">
        <img
          :src="require('@/assets/bestellung/xxlkfoPicto.jpg')"
          style="width: 60px; float: left"
        />
      </v-col>
    </v-row>

    <v-row>
      <v-col :cols="1" :md="1" class="my-0 py-0"> </v-col>
      <v-col :cols="11" :md="9" class="my-0 py-0">
        <div class="caption pa-4 mb-4" style="background-color: #f2f2f2">
         <h3><a @click="show_comment = !show_comment"
            >Sie benötigen eine größere Menge?</a
          ></h3>
          Klicken
          <a @click="show_comment = !show_comment">Sie jetzt hier</a> und
          schreiben Sie uns, wieviel Sie genau benötigen.
         
        <span v-if="show_comment">
          <br /><br />
          <v-textarea
            label="Welche Menge benötigen Sie?"
            style="width: 400px;" bg-color="white"
            v-model="bestellung.bestell_comment"
          ></v-textarea>
   
        </span>
        </div>
      
        <h2 v-if="bestellung.bestell_produkt_text == ''" class="text-orange">
          Bitte wählen Sie die gewünschten Tabellen
        </h2>
        <v-btn
          v-if="bestellung.bestell_produkt_text != ''"
          color="primary"
          style="height: 48px; font-size: 16px"
          elevated
          tile
          @click="submitForm"
          type="submit"
          class="button_caps"
          tonal
        >
          <span class="white--text">
            <v-icon style="font-size: 28px">play_arrow</v-icon> jetzt<span
              class="hidden-xs-only"
              >&nbsp;kostenlos neue&nbsp;</span
            >WaizmannTabellen anfordern
          </span>
        </v-btn>
        <div
          class="py-4 pl-4 mt-4"
          v-if="bestellung.bestell_produkt_text != ''"
        >
          <h2 class="text-green">Ist Ihre Adresse noch korrekt?</h2>
          <ul>
            <li>{{ user.user_name }}</li>
            <li>{{ user.user_strasse }} {{ user.user_hsnr }}</li>
            <li>{{ user.user_plz }} {{ user.user_ort }}</li>
            <li>
              <router-link to="/account">Adresse aktualisieren</router-link>
            </li>
          </ul>
        </div>
      </v-col>
    </v-row>
  </div>
</template>

<script setup>
  import { reactive, ref, defineEmits, watch, defineProps } from "vue";
  import { useUserStore } from "@/stores/user";
  import BaseCalls from "@/services/BaseCalls";
  import { useErrorStore } from "@/stores/errorStore";
  import { useSuccessStore } from "@/stores/successStore";
  import { useRouter } from "vue-router";
  const router = useRouter();
  // Definiere Props mit defineProps
  const props = defineProps({
    redirect: {
      type: String,
      default: "",
    },
  });
  import { useSharedDataStore } from "@/stores/sharedData";
  const sharedDataStore = useSharedDataStore();
  const errorStore = useErrorStore();
  const successStore = useSuccessStore();

  const userStore = useUserStore();
  const user = ref(false);
  user.value = userStore.userData;
  watch(
    () => userStore.userData,
    () => {
      user.value = userStore.userData;
    },
    {
      deep: true, // Wenn userData ein Objekt ist und Sie tiefe Änderungen überwachen möchten
    }
  );

  const emit = defineEmits(["updateData"]);

  const sendDataToParent = (data) => {
    emit("updateData", data);
  };

  const show_comment = ref(false);
  const bestellung = reactive({
    bestell_status: 0,
    bestell_comment: "",
    bestell_produkt_text: "",
    bestell_kategorie: 0,
  });
  function addOderEntferneProdukt(titel, was) {
    if (was == "webinar") {
      bestellung.bestell_kategorie = 1;
    } else if (was == "tabelle") {
      bestellung.bestell_kategorie = 0;
    }

    if (bestellung.bestell_produkt_text) {
      if (bestellung.bestell_produkt_text.includes(titel)) {
        // Entfernt den Titel, wenn er bereits vorhanden ist
        let produkte = bestellung.bestell_produkt_text.split(", ");
        let index = produkte.indexOf(titel);
        if (index !== -1) {
          produkte.splice(index, 1); // Entfernt den Titel
          bestellung.bestell_produkt_text = produkte.join(", ");
        }
      } else {
        // Fügt den Titel hinzu, wenn er noch nicht vorhanden ist
        bestellung.bestell_produkt_text += `, ${titel}`;
      }
    } else {
      // Setzt den Titel, wenn bestell_produkt_text noch leer ist
      bestellung.bestell_produkt_text = titel;
    }

    console.log(bestellung.bestell_produkt_text);
  }

  async function submitForm() {
    bestellung.user_action = "insertBestellung"; // Korrigiert, um .value zu verwenden
    try {
      const resp = await BaseCalls.postBestellungLogged(bestellung); // Korrigiert, um .value zu verwenden
      if (resp.data && resp.data.success) {
        successStore.setSuccess(
          true,
          "Wir haben Ihre Bestellung erhalten",
          "Wir schicken Ihnen die gewünschten WaizmannTabellen so schnell wie möglich zu. Natürlich kostenlos."
        );
        if (props.redirect == "bestellung") {
          router.push("/waizmanntabellen_bestellen");
        } else {
          sendDataToParent(true);
        }
      } else {
        errorStore.setError(true, resp.data.errortext);
      }
    } catch (err) {
      errorStore.setError(
        true,
        "Ihre Bestellung konnten nicht angelegt werden"
      );
    }
  }

  // Zugriff auf das assoziative Array durch props.userDetails (angepasst, um der aktuellen Prop-Namenskonvention zu entsprechen)
</script>