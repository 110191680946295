<template>
 <div>
  <v-row class="ml-12" style="max-width: 1400px;">

<v-col  style="border-right: 2px solid #E5EDF4;">
  <span class="head20">WaizmannTabellen ToGo</span><br>
  <div class="mt-4 head18">Erwachsene & Kinder</div>
  <div  class="mt-4 head18">Prophylaxe <v-icon class="text-green">mdi-new-box</v-icon>  </div>
  <div  class="mt-4 head18"><router-link to="/login/waizmanntabellen"><b>WaizmannTabellen anfordern</b></router-link></div>

</v-col>
<v-col class="pl-8"  style="border-right: 2px solid #E5EDF4;" ><span class="head20">WaizmannTabellen XXL</span><br>

  <div  class="mt-4 head18">Erwachsene XXL</div>
  <div  class="mt-4 head18">Kinder & KFO</div>
  <div  class="mt-4 head18"><router-link to="/login/waizmanntabellen"><b>WaizmannTabellen XXL anfordern</b></router-link></div>
  
</v-col>
<v-col class="pl-8"  style="border-right: 2px solid #E5EDF4;" ><span class="head20">Gesamtausgabe 2024</span><br>

<div  class="mt-4 head18">Gesamtausgabe 2024</div>
<div  class="mt-4 head18"><router-link to="/login/waizmanntabellen"><b>Gesamtausgabe anfordern</b></router-link></div>

</v-col>
<v-col>
  <span class="head20">Gerade bestellt</span><br>
  <span v-for="bestellung in bestellungen" :key="bestellung.bestell_id">
              <span class="mr-2"> <b class="text-green head18">Zahnarztpraxis {{ bestellung.user_ort }}</b>   {{ sharedDataStore.getProduktText(bestellung.bestell_produkt_text) }} ({{ sharedDataStore.getTimeAgo(bestellung.earliest_created_date) }}) </span>
              <span class="mx-2"> | </span>
            </span>
</v-col>
</v-row>
 </div>
</template>


<script setup>
import {ref,onMounted } from "vue";
import BaseCalls from "@/services/BaseCalls";
const  bestellungen = ref(false);

import { useSharedDataStore } from "@/stores/sharedData";
const sharedDataStore = useSharedDataStore();

async function fetchAlleUserBestellungen() {

try {
  const data = {
    action: "getBestellungen",
    select: "getShowBestellungen",
    limit: 2
  };
  const resp = await BaseCalls.getBestellungPost(data);
  if (resp.data && resp.data.bestellungen) {
    bestellungen.value = resp.data.bestellungen;
  
  }
} catch (err) {
  console.log("Fehler bei der Aktion:", err.message);
}
}

onMounted(() => {
fetchAlleUserBestellungen();
});
</script>
