<template>
  <div class="mt-8">
    <v-row>
      <v-col cols="12" md="9">
        <v-row>
          <v-col cols="12">
        <h2  >Team<span class="text-blue">Waizmann</span> Praxiswissen:</h2>
        <h1>Professionelle Zahnreinigung | PZR</h1>
        Keine Leistung wird bei einer Zahnzusatzversicherung mehr in Anspruch
        genommen als die PZR. Viele Patienten schließen eine ZZV ausschließlich
        deshalb ab, um regelmäßige Zahnreinigungen erstattet zu bekommen.
        Deshalb ergeben sich in der Praxis immer wieder Fragen zum Thema: ZZV &
        PZR. Im neuen ZZV-Praxiswissen beantworten unsere Leistungsexperten die
        wichtigsten Fragen von Zahnarztpraxen.
        
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="6">
        <v-card
     class="mx-auto"
    subtitle="WaizmannTabelle für Ihre Praxis anfordern"
    width="400"
  >
    <template v-slot:prepend>
      <v-icon color="green" size="x-large">mdi-cart</v-icon>
    </template>
    <template v-slot:title>
      <span class="font-weight-black">WaizmannTabelle PZR-Flatrate</span>
    </template>

    <v-card-text class="bg-surface-light pt-4">
      <v-row>
        <v-col cols="7">Nutzen Sie die neue  <b>WaizmannTabelle | PZR-Flatrate</b> für den Prophylaxe-Bereich in Ihrer Praxis.
          <br><br> <ul><li><router-link to="/waizmanntabellen_bestellen">
            Jetzt kostenlos in gewünschter Stückzahl <b>anfordern...</b></router-link
            ></li>
          <li><a href="/dist/praxiswissen/WaizmannTabelle_PZR_Flatrate.pdf" target="_blank">Download als <b>PDF</b> (für E-Mail Versand)</a></li>
          </ul>
        </v-col>
        <v-col cols="5">    <img
        :src="require('@/assets/bestellung/pzrPicto.jpg')"
        style="width: 100px; float: left;"
      /></v-col>
      </v-row>
    .
    </v-card-text>
  </v-card> 


<v-card
class="mx-auto mt-12"
subtitle="Der Waizmann PZR-Link für Ihre Praxiswebsite"
width="400"
>
<template v-slot:prepend>
<v-icon color="blue" size="x-large">mdi-link</v-icon>
</template>
<template v-slot:title>
<span class="font-weight-black">Prophylaxe "<span class="text-blue">PraxisLink</span>"</span>
</template>

<v-card-text class="bg-surface-light pt-4">
<v-row>
  <v-col cols="12">Immer Zahnarztpraxen integrieren die Digitale Ausgaben der WaizmannTabelle in Ihre Praxiswebsites.
    Die <b>WaizmannTabelle <span class="text-pink">PZR-Flatrate</span></b> können Sie mit diesem Link in Ihrer Praxiswebsite integrieren.<br /><br />
<a href="https://www.waizmanntabelle.de/pzr_flatrate" target="_blank"><b>www.waizmanntabelle.de/pzr_flatrate</b></a>
<ul class="mt-8">
        <li>
            <a
              href="/dist/praxislink/praxislink_pzr.pdf"
              target="_blank"
              >Einbauanleitung PZR-Praxislink </a
            > <sup class="text-green">neu</sup>
          </li>
      
 
        </ul>

</v-col>
</v-row>
.
</v-card-text>
</v-card> 

 
  </v-col>
      <v-col cols="6">
        <v-card
     class="mx-auto"
    subtitle="Hintergrundwissen für zahnmed. Fachpersonal"
    width="400"
  >
    <template v-slot:prepend>
      <v-icon color="green" size="x-large">mdi-cart</v-icon>
    </template>
    <template v-slot:title>
      <span class="font-weight-black">Schulungs-Infomaterial</span>
    </template>

    <v-card-text class="bg-surface-light pt-4">
      <ul class="mt-2">
        <li>
            <a
              href="/dist/praxiswissen/PraxisWissen_Kinderprophylaxe.pdf"
              target="_blank"
              >ZZV-PraxisWissen PZR für Kinder </a
            > <sup class="text-green">neu</sup>
          </li>
          <li>
            <a
              href="/dist/praxiswissen/PraxisWissen_Prophylaxe.pdf"
              target="_blank"
              >ZZV-PraxisWissen Professionelle Zahnreinigung </a
            >
          </li>
          <li>
            <a
              href="/dist/praxiswissen/Waizmann_Praxisumfrage_Prophylaxe.pdf"
              target="_blank"
              >Praxisumfrage 2024 Prophylaxe - Ergebnisse</a
            >
          </li>
        </ul>
    </v-card-text>
  </v-card> 
  
  <v-card
     class="mx-auto mt-12"
    subtitle="Kostenfreie Webinare für Zahnärzte & ZFA"
    width="400"
  >
    <template v-slot:prepend>
      <v-icon color="green" size="x-large">mdi-presentation-play</v-icon>
    </template>
    <template v-slot:title>
      <span class="font-weight-black">TeamWaizmann Webinare</span>
    </template>

    <v-card-text class="bg-surface-light pt-4">
      <router-link to="/webinare/webinar_profit_booster_professionelle_zahnreinigung" class="noLink"
              ><b>Webinar</b> <br />Profit Booster Professionelle Zahnreinigung</router-link
            >    <router-link to="/webinare/webinar_profit_booster_professionelle_zahnreinigung"
              >...jetzt kostenlos anmelden</router-link
            >
    </v-card-text>
  </v-card> 
  
      </v-col>
     
  
    </v-row>
      </v-col>
      <v-col cols="12" md="3"> </v-col>
    </v-row>
    
  </div>
</template>

<script setup>
  import { getCurrentInstance, onMounted } from "vue";

  onMounted(() => {
    const { proxy } = getCurrentInstance();
    proxy.$updateDocumentHead(
      "TeamWaizmann Praxiswissen: Professionelle Zahnreinigung",
      "Professionelle Zahnreinigung | PZR"
    );
  });
</script>

