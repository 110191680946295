<template>
    <v-container>
        <h2 class="mb-2">
          Bye, bye BEMA - Abschied in 6 Schritten |
          <span class="text-blue">kostenlos</span> auf TeamWaizmann
        </h2>

        <div class="mb-2">
          <strong>Zielgruppe:</strong> Zahnärzte und Praxismanager
        </div>
        <div class="mb-2">
          <strong>Ziel:</strong> Zahnarztpraxen zu helfen ihren GOZ-Anteil zu
          erhöhen und damit unabhängiger von gesundheitspolitischen
          Rahmenbedingungen zu werden.
        </div>
        <div class="mb-2">
          <strong>Inhalt:</strong> Höherer Verwaltungsaufwand, steigende
          Patientenzahlen und erneute Budgetierung machen es Zahnärzten immer
          schwieriger, angemessen für ihre eigentliche Arbeit entlohnt zu
          werden. Zahnarzt-Coach Frank Frenzel zeigt Zahnärzten in 6 einfachen
          Schritten wie sie ihre Praxis zu einer zukunftsorientierten,
          finanziell unabhängigen Zahnarztpraxis mit ruhigen und stabilen
          Praxisabläufen entwickeln und sich so auch für neue Mitarbeiterinnen
          und Mitarbeiter attraktiv machen.
        </div>
        <div class="mb-2">
          Dieses Webinar bietet wertvolle Einblicke und praktische Tipps, um
          Ihre Praxis auf ein ganz neues Erfolgsniveau zu heben. Zahnärzte haben
          direkt im Webinar die Möglichkeit Fragen zu stellen und fundierte
          Tipps zu erhalten, die sich in der Praxis hundertfach bewährt haben
          und sofort umsetzbar sind.
        </div>
        <div class="mb-2">
          <strong>Auf den Punkt:</strong> “Wie Sie Ihr Praxiskonzept und Ihre
          interne und externe Kommunikation so optimieren, dass Ihnen mit einem
          BEMA / GOZ-Verhältnis von 15 / 85 gesundheitspolitische (Fehl-)
          Entscheidungen schlimmstenfalls ein Achselzucken entlocken.” Zitat:
          Frank Frenzel
        </div>
        <div class="mb-2">
          <strong>Dauer:</strong> 90 Minuten + offene Fragerunde im Anschluss
        </div>
        <div class="mb-2">
          <strong>Teilnehmerzahl:</strong> max. 50 Teilnehmer
        </div>
        <div class="mb-2">
          <strong>Durchführung:</strong> Zoom-Webinar: Den Anmeldelink und Ihre
          Zugangsdaten erhalten Sie ca. 7 Tage vor dem Webinar per E-Mail.
        </div>
    </v-container>
  </template>
  
  <script setup>

  </script>
  