<template>
    <v-container>
        <h2 class="mb-2">
          Webinar  "Profit Booster Professionelle Zahnreinigung" |
          <span class="text-blue">kostenlos</span> auf TeamWaizmann
        </h2>

        <div class="mb-2">
          <strong>Zielgruppe:</strong> Zahnärzte und Praxismanager
        </div>
        <div class="mb-2">
          <strong>Ziel:</strong> Sie entdecken, was Ihnen bisher an Praxisgewinn in der PZR verloren geht und wie Sie mit wenigen Änderungen zwischen 20 und 50.000,- € Plus beim Gewinn erzielen können


        </div>
        <div class="mb-2">
          <strong>Inhalt:</strong> Die meisten Praxen bleiben in der PZR weit hinter ihren Möglichkeiten zurück. Dies bedeutet sowohl eine zu niedrige PZR-Quote, als auch eine zu geringe Auslastung und eine zu geringe Produktivität. Lassen Sie sich nicht durch „3 Monate Vorlauf“ blenden - im Rückblick liegt die Auslastung meist bei unter 80%, die Produktivität erreicht oft die 90% nicht. Die PZR Quote liegt in vielen Praxen unter dem Bundesschnitt von 61%. Der Grund dafür ist simpel: Der Praxisinhaber ist sich dieses Umstandes oft nicht bewußt - die PZR- Mitarbeiterin dagegen kennt den beschriebenen Mißstand oft sehr wohl, hat aber 
(außer zusätzlicher Anstrengung) nichts davon, ihn zu ändern. Erfahren Sie, wie Sie diesen Mißsttand durch ein einfach zu handhabendes, umsatzbezogenes Modell der Erfolgsbeteiligung ändern können.


        </div>
        <div class="mb-2"><b>Auf den Punkt: </b>
           <em>„Wer seine Mitarbeiter(in) nicht am Erfolg beteiligt, braucht sich nicht wundern, wenn die sich auch nicht an der Anstrengung beteiligen möchte, die nötig ist, um das gewünschte Gewinnziel zu erreichen.“</em> <br>  Zitat: Frank Frenzel
        </div>
       
        <div class="mb-2">
          <strong>Dauer:</strong> 45 Minuten
        </div>
        <div class="mb-2">
          <strong>Teilnehmerzahl:</strong> max. 50 Teilnehmer
        </div>
        <div class="mb-2">
          <strong>Durchführung:</strong> Zoom-Webinar: Den Anmeldelink und Ihre
          Zugangsdaten erhalten Sie ca. 7 Tage vor dem Webinar per E-Mail.
        </div>
    </v-container>
  </template>
  
  <script setup>

  </script>
  