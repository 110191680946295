import axios from "axios";

var baseURL = "https://www.teamwaizmann.de";

if (
  window.location.hostname == "localhost" ||
  window.location.hostname == "loc.teamwaizmann.de"
) {
  baseURL = "https://loc.teamwaizmann.de";
}

if (window.location.hostname == "www.teamwaizmann.de") {
  baseURL = "https://www.teamwaizmann.de";
}

const http = axios.create({
  baseURL: baseURL,
  withCredentials: true,
  headers: {
    "Content-Type": "application/json",
  },
});

export default http;
