<template>
  <v-overlay v-model="overlay" class="justify-center">
    <v-card width="666" class="mt-12 pa-4">
      <v-row>
        <v-col cols="12" md="9">
          <h2 class="text-green">{{ successTitle }}</h2></v-col
        >
        <v-col cols="12" md="3">
          <v-btn color="primary" @click="clearSuccess()">
            schliessen
          </v-btn></v-col
        >
      </v-row>
      <v-row>
        <v-col cols="12" md="9">
          {{ successMessage }}
        </v-col>
      </v-row>
    </v-card>
  </v-overlay>
</template>

<script setup>
  import { computed } from "vue";
  import { useSuccessStore } from "@/stores/successStore";

  const successStore = useSuccessStore();

  const overlay = computed({
    get: () => !!successStore.load,
    set: (value) => !value && successStore.clearSuccess(),
  });

  const successTitle = computed(() => successStore.title || "Alle klar!");
  const successMessage = computed(
    () => successStore.message || "Es hat funktioniert."
  );

  function clearSuccess() {
    successStore.clearSuccess();
  }
</script>
