<template>
  <v-overlay v-model="overlay" opacity="0.7" class="justify-center">
    <v-card width="666" class="mt-12 pa-4">
      <v-row>
        <v-col cols="12" md="9">
          <h2 class="text-green">{{ loaderMessage }}</h2></v-col
        >
        <v-col cols="12" md="3">
          <v-btn color="primary" @click="clearLoader()">
            schliessen
          </v-btn></v-col
        >
      </v-row>
    </v-card>
  </v-overlay>
</template>

<script setup>
  import { computed } from "vue";
  import { useLoaderStore } from "@/stores/loaderStore";

  const loaderStore = useLoaderStore();

  const overlay = computed({
    get: () => !!loaderStore.load,
    set: (value) => !value && loaderStore.clearLoader(),
  });

  const loaderMessage = computed(
    () => loaderStore.message || "Ein unbekannter Fehler ist aufgetreten."
  );

  function clearLoader() {
    loaderStore.clearLoader();
  }
</script>
