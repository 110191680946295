<template>
  <v-container>
    <v-row>
      <v-col cols="12" md="9">
        <NeueBestellung @updateData="handleDataUpdate" class="mb-8" />
      </v-col>
      <v-col cols="12" md="3">
        <br />
        <br />
        <br /> <br />
        <h3>Ihre Bestell-Historie</h3>
        <div v-if="bestellungen">
          <ul>
            <li   v-for="best in bestellungen"
            :key="best.bestell_id">
              <span v-if="best.bestell_produkt_text == ''"
                ><b>WaizmannTabellen</b></span
              >
              <span v-if="best.bestell_produkt_text != ''">
                <b>{{ best.bestell_produkt_text }}</b>
                <div v-if="best.bestell_comment" class="py-2">
                  {{ best.bestell_comment }}
                </div>
                </span>
                <br /><span :class="best.statusClass">{{ best.status }}</span
                  > | {{ best.datum }}
            </li>
          </ul>
  
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script setup>
  import { ref, onMounted,getCurrentInstance, defineComponent } from "vue";

  import { useUserStore } from "@/stores/user";
  import BaseCalls from "@/services/BaseCalls";
  import { useLoaderStore } from "@/stores/loaderStore";

  import NeueBestellung from "@/components/user/NeueBestellung.vue";
  const { proxy } = getCurrentInstance();
  defineComponent({
    components: {
      NeueBestellung,
    },
  });

  const handleDataUpdate = async () => {
    try {
      const data = {};
      data.action = "getAlleUserBestellungen";
      const resp = await BaseCalls.getBestellung(data);
      if (resp.data && resp.data.success) {
        // Angenommen, bestellungen ist eine ref und wurde bereits definiert
        bestellungen.value = resp.data.bestellungen;
      }
    } catch (err) {
      console.log("Fehler bei der Aktion:", err.message);
    }
  };

  const loaderStore = useLoaderStore();
  const userStore = useUserStore();
  const bestellungen = ref(false);

  function setLoading(loading) {
    loaderStore.setLoader(loading);
  }
  onMounted(() => {
   
 
    proxy.$updateDocumentHead(
      "TeamWaizmann WaizmannTabellen kostenlos anfordern",
      "WaizmannTabellen kostenlos anfordern"
    );

    setLoading(true);
    (async () => {
      if (userStore.isLogged) {
        console.log("Benutzer ist eingeloggt - jetzt bestellnugen");
        try {
          const data = {};
          data.action = "getAlleUserBestellungen";
          const resp = await BaseCalls.getBestellung(data);
          if (resp.data && resp.data.success) {
            bestellungen.value = resp.data.bestellungen;
            setLoading(false);
          }
        } catch (err) {
          console.log("Fehler bei der Aktion:", err.message);
        }
      } else {
        console.log("Benutzer ist nicht eingeloggt");
      }
    })();
  });
</script>
