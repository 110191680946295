<template>
  <div class="pt-8 pl-0">
    <v-row v-if="showTeaser == 'waizmannwebinare'">
        <v-col cols="9"><h2>Anmeldung Webinar</h2> <h3>Loggen Sie sich ein oder registrieren Sie sich kostenlos</h3>
          Um sich für ein WaizmannWebinar anzumelden, loggen Sie sich bitte zuerst ein oder registrieren Sie sich kostenlos.
        </v-col>
    </v-row>
    <v-row v-if="showTeaser == 'waizmanntabellen'">
        <v-col cols="9"><h2>WaizmannTabellen anfordern</h2>
          <h3>Loggen Sie sich ein oder registrieren Sie sich kostenlos</h3>
          Um kostenlos neue WaizmannTabellen anzufordern, loggen Sie sich bitte zuerst ein oder registrieren Sie sich kostenlos.
        </v-col>
    </v-row>
<v-row>
  <v-col cols="9">
<DialogLogin width="888" class="mt-2" />
<v-card elevation="1" class="mt-12">
<RegisterUser  width="888" />
</v-card>
</v-col>
</v-row>
  </div>
</template>

<script setup>
  import { ref,getCurrentInstance,onMounted } from "vue";
  import { useRoute } from "vue-router";
  import DialogLogin from "./../components/user/DialogLogin.vue";
  import RegisterUser from "./../components/user/RegisterUser.vue";
  const route = useRoute();
  const showTeaser = ref()

  onMounted(() => {
    if (route.params.thema != ''){
      showTeaser.value = route.params.thema;
    }
    const { proxy } = getCurrentInstance();
    proxy.$updateDocumentHead(
      "TeamWaizmann - Login",
      "TeamWaizmann - Login"
    );
   
  });
</script>
