<template>
  <div>
   <v-row class="ml-12" style="max-width: 999px;">
 
 <v-col  style="border-right: 2px solid #E5EDF4;">
   <span class="head20"><span class="text-blue">PraxisLinks </span>für Ihre Website</span><br>
   <div class="mt-4 head18">WaizmannTabelle Digital-Ausgabe | komplett</div>
   <div class="mt-4 head18">Kfo & Kinder</div>
   <div class="mt-4 head18">Prophylaxe</div>
   <div  class="mt-4 head18"><router-link to="/digitalausgabe"><b>alle PraxisLinks anzeigen</b></router-link></div>
 </v-col>
 <v-col  style="border-right: 2px solid #E5EDF4;">
 
  </v-col>

 
 </v-row>
  </div>
 </template>
 
 <script setup>
 
 </script>
 