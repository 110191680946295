<template>
  <v-container
    ><ImpressumInfo v-if="showView == 'impressum'" />
    <PraxiswissenInfo v-if="showView == 'praxiswissen'" />
  </v-container>
</template>

<script setup>
  import { ref, onMounted, getCurrentInstance } from "vue";
  import { useRoute } from "vue-router";
  import ImpressumInfo from "./../components/info/ImpressumInfo.vue";
  import PraxiswissenInfo from "./../components/info/PraxiswissenInfo.vue";

  const route = useRoute();
  const showView = ref("");
  showView.value = route.params.site;

  onMounted(() => {
    const { proxy } = getCurrentInstance();
    if (showView.value == "impressum") {
      proxy.$updateDocumentHead(
        "Impressum TeamWaizmann",
        "Impressum TeamWaizmann"
      );
    }
  });
</script>
