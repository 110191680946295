<template>
  <v-container>
    <v-row>
      <v-col cols="12" md="6">
        <h2>Hier können Sie Ihr Passwort ändern</h2>
        Nach der Änderung Ihres Passwortes werden Sie aus Sicherheitsgründen
        ausgeloggt. Loggen Sie sich bitte danach neu ein.
        <br />
        <br />
        <v-form ref="form" v-model="valid">
          <v-text-field
            v-model="userneu.user_pass_1"
            label="Ihr neues Passwort"
            type="password"
            :rules="[rules.required]"
          ></v-text-field>
          <v-text-field
            v-model="userneu.user_pass_2"
            label="Wiederholen Sie das Passwort"
            type="password"
            :rules="[rules.required]"
          ></v-text-field>
          <v-btn
            :disabled="!valid"
            class="ml-4"
            color="primary"
            @click="submitForm"
          >
            Neues Passwort jetzt speichern
          </v-btn>
        </v-form>
      </v-col>
    </v-row>
  </v-container>
</template>

<script setup>
  import { ref, onMounted } from "vue";
  import BaseCalls from "@/services/BaseCalls";
  import { useErrorStore } from "@/stores/errorStore";
  import { useSuccessStore } from "@/stores/successStore";
  import { useUserStore } from "@/stores/user";
  import { useRouter } from "vue-router";

  const router = useRouter();
  const errorStore = useErrorStore();
  const successStore = useSuccessStore();
  const userStore = useUserStore();

  const userneu = ref({
    user_pass_1: "",
    user_pass_2: "",
  });

  const user = ref(null);
  const form = ref(null);
  const rules = {
    required: (value) => !!value || "Erforderlich.",
  };

  const valid = ref(true); // Annahme: Das Formular ist zunächst gültig

  const fetchUser = async () => {
    let select = { action: "loggedUser", id: 0 };
    try {
      const resp = await BaseCalls.getUser(select);
      if (resp.data) {
        user.value = resp.data.user; // Aktualisiere user Daten
      }
    } catch (err) {
      console.error(err);
    }
  };

  const submitForm = async () => {
    if (userneu.value.user_pass_1 !== userneu.value.user_pass_2) {
      errorStore.setError(true, "Die Passwörter stimmen nicht überein.");
      return;
    }
    let updateData = {
      user_action: "changePassword",
      user_pass: userneu.value.user_pass_1,
      user_id: user.value.user_id,
    };
    try {
      const resp = await BaseCalls.postUserLogged(updateData);
      if (resp.data && resp.data.success) {
        console.log("Passwort erfolgreich geändert");
        await startLogout(); // Logout nach Passwortänderung
        successStore.setSuccess(
          true,
          "Passwort geändert",
          "Ihr Passwort wurde geändert. Bitte loggen Sie sich erneut ein."
        );
        router.push("/");
      }
    } catch (err) {
      errorStore.setError(
        true,
        "Beim Ändern des Passworts ist ein Fehler aufgetreten."
      );
    }
  };

  async function startLogout() {
    let data = { user_action: "startLogout" };
    try {
      const resp = await BaseCalls.postUser(data);
      if (resp.data && resp.data.success) {
        userStore.setUser(null);
        userStore.isLogged = false;
      }
    } catch (err) {
      errorStore.setError(
        true,
        "Beim Abmelden ist ein technischer Fehler aufgetreten."
      );
    }
  }

  onMounted(fetchUser);
</script>
