<template>
  <v-container>
    <v-row>
      <v-col cols="12" md="9">
        <h2 class="text-green">Profildaten bearbeiten | Name, Adresse ....</h2>

        <v-form @submit.prevent="submitForm">
          <v-text-field
            v-model="user.user_firma"
            label="Praxis"
            type="char"
            required
          ></v-text-field>
          <v-text-field
            v-model="user.user_vorname"
            label="Vorname"
            type="char"
            required
          ></v-text-field>
          <v-text-field
            v-model="user.user_name"
            label="Name"
            type="char"
            required
          ></v-text-field>
          <v-row>
            <v-col cols="12" md="9">
              <v-text-field
                v-model="user.user_strasse"
                label="Strasse"
                type="char"
                required
              ></v-text-field
            ></v-col>
            <v-col cols="12" md="3">
              <v-text-field
                v-model="user.user_hsnr"
                label="Hausnummer"
                type="char"
                required
              ></v-text-field
            ></v-col>
          </v-row>

          <v-row>
            <v-col cols="12" md="3">
              <v-text-field
                v-model="user.user_plz"
                label="PLZ"
                type="char"
                required
              ></v-text-field
            ></v-col>
            <v-col cols="12" md="9">
              <v-text-field
                v-model="user.user_ort"
                label="Ort"
                type="char"
                required
              ></v-text-field
            ></v-col>
          </v-row>

          <v-btn class="ml-4" type="submit" color="primary"
            >Daten jetzt aktualisieren</v-btn
          >
        </v-form>
      </v-col>
    </v-row>
  </v-container>
</template>

<script setup>
  import { onMounted, ref } from "vue";
  import BaseCalls from "@/services/BaseCalls";
  //   import { useUserStore } from "@/stores/user";
  import { useErrorStore } from "@/stores/errorStore";
  import { useSuccessStore } from "@/stores/successStore";

  //   const userStore = useUserStore();
  const errorStore = useErrorStore();
  const successStore = useSuccessStore();

  const user = ref(false); // Initialisiert `user` als reaktiv mit dem Anfangswert `false`

  const fetchUser = async () => {
    let select = {};
    select.action = "loggedUser";
    select.id = 0;
    try {
      const resp = await BaseCalls.getUser(select);
      if (resp.data) {
        user.value = resp.data.user; // Aktualisiere user Daten
      }
    } catch (err) {
      console.error(err);
    }
  };

  const submitForm = async () => {
    let userUpdate = {};
    userUpdate = user.value;
    userUpdate.user_action = "updateAccount";
    //successStore.setSuccess(true, "Ihr Daten wurde geändert");
    try {
      const resp = await BaseCalls.postUserLogged(userUpdate);
      if (resp.data && resp.data.success) {
        console.log("success data change");
        successStore.setSuccess(
          true,
          "Gespeichert",
          "Ihr Daten wurde geändert"
        );
      } else {
        //  throw new Error("Anmeldedaten sind ungültig."); // Benutzerdefinierte Fehlermeldung
      }
    } catch (err) {
      errorStore.setError(
        err.message || "Beim Anmelden ist ein Fehler aufgetreten."
      );
    }
  };

  onMounted(fetchUser);
</script>
