import { defineStore } from "pinia";

export const useLoaderStore = defineStore("loaderStore", {
  state: () => ({
    load: false, // Initialisiert mit false, um das Laden nicht sofort anzuzeigen
    message: "Der Inhalt wird geladen.", // Standardfehlermeldung
  }),
  actions: {
    setLoader(load, message = "") {
      this.load = load;
      // Verwende die übergebene Nachricht, falls vorhanden, sonst behalte die aktuelle Nachricht bei
      if (message) this.message = message;
    },
    clearLoader() {
      this.load = false; // Setzt `load` auf false statt auf null
      this.message = "Der Inhalt wird geladen."; // Setze die Nachricht zurück auf den Standardwert
    },
  },
});
