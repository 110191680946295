<template>
  <v-container>
    <v-row style="cursor: pointer">
      <v-col cols="10" md="10"
        ><b style="font-size: 20px"
          ><a @click="menue2 = !menue2">WaizmannTabellen</a></b
        >
        <ul class="ml-2 mt-2" v-if="menue2">
          <li>
            <router-link to="/waizmanntabellen_bestellen" class="noLink"
              ><b>WaizmannTabelle Togo, XXL, Gesamtausgabe</b></router-link
            >
            <router-link class="noUnderline" to="/waizmanntabellen_bestellen">
              kostenlos bestellen</router-link
            >
          </li>
          <li class="mt-2">
            <router-link to="/digitalausgabe" class="noLink"
              ><b>Digitalausgabe in Praxis-Webseite</b></router-link
            ><br />
            <router-link class="noUnderline" to="/digitalausgabe">integrieren</router-link>
          </li>
          <li class="mt-2">
            <router-link to="/download_waizmanntabelle" class="noLink"
              ><b>Download WaizmannTabellen</b></router-link
            ><br />
            <router-link class="noUnderline" to="/download_waizmanntabelle">für Email-Versand</router-link>
          </li>
        </ul>
      </v-col>
      <v-col cols="1" md="1"
        ><v-icon v-if="!menue2">mdi-chevron-right</v-icon
        ><v-icon v-if="menue2">mdi-chevron-down</v-icon>
      </v-col>
    </v-row>

    <v-row style="cursor: pointer">
      <v-col cols="10" md="10"
        ><b style="font-size: 20px"
          ><a @click="menue3 = !menue3">Seminare & Info</a></b
        >
        <ul class="ml-2 mt-2" v-if="menue3">
          <li>
            <router-link to="/webinare/webinar_profit_booster_professionelle_zahnreinigung" class="noLink"
              ><b>Webinar</b> <br />Profit Booster Professionelle Zahnreinigung</router-link
            >
            <router-link class="noUnderline" to="/webinare/webinar_profit_booster_professionelle_zahnreinigung"
              >...jetzt kostenlos anmelden</router-link
            >
          </li>
        </ul>
      </v-col>
      <v-col cols="1" md="1"
        ><v-icon v-if="!menue3">mdi-chevron-right</v-icon
        ><v-icon v-if="menue3">mdi-chevron-down</v-icon>
      </v-col>
    </v-row>
    <v-row style="cursor: pointer">
      <div class="my-4 pa-4 ml-2" style="border-left: 4px solid #2196F3">
      <h3 >Praxiswissen</h3>
    
            <router-link to="/prophylaxe" class="noLink"
              ><b style="font-size: 32px;" class="text-blue">Prophylaxe</b> </router-link
            >
            <ul class="ml-8">
              <li>Webinare</li>
              <li>Downloads</li>
              <li>WaizmannTabellen</li>
              <router-link class="noUnderline" to="/prophylaxe" 
              >alle Infos zu Prophylaxe</router-link>
            
            </ul>
          </div>
    </v-row>
    <v-row style="cursor: pointer">
      <v-col cols="10" md="10"
        ><b style="font-size: 20px"
          ><a @click="menue1 = !menue1">Ihr Account</a></b
        >
        <ul class="ml-8 mt-2" v-if="menue1">
          <li><router-link class="noUnderline" to="/account">Adresse bearbeiten</router-link></li>
          <li class="mt-2">
            <router-link class="noUnderline" to="/password_change">Passwort ändern</router-link>
          </li>
        </ul>
      </v-col>
      <v-col cols="1" md="1"
        ><v-icon v-if="!menue1">mdi-chevron-right</v-icon
        ><v-icon v-if="menue1">mdi-chevron-down</v-icon>
      </v-col>
    </v-row>
  </v-container>
</template>

<script setup>
  import { ref } from "vue";
  const menue1 = ref(false);
  const menue2 = ref(true);
  const menue3 = ref(true);
  
</script>
