<template>
   <div class="ml-0">
      <v-card elevation="0">
      <v-card-text>
    <h2 class="mb-2">Jetzt kostenlos bei Team<span class="text-blue">Waizmann</span> anmelden</h2>
Nutzen auch Sie jetzt die kostenlosen TeamWaizmann Services für Ihre Zahnarztpraxis.<br><br>
    <v-form ref="form" @submit.prevent="submitForm">
      <v-row>
        <v-col cols="12" md="6">
          <v-text-field
            v-model="userdata.user_firma"
            label="Praxisname"
            required
          ></v-text-field>
        </v-col>

        <v-col cols="12" md="6">
          <v-text-field
            v-model="userdata.user_email"
            :rules="[checkTextFeld('user_email')]"
            label="E-Mail"
            required
          ></v-text-field>
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="12" md="2">
          <v-select
            label="Anrede"
            :items="['Frau', 'Herr', 'Dr.', 'Prof.']"
          ></v-select
        ></v-col>
        <v-col cols="12" md="5">
          <v-text-field
            v-model="userdata.user_vorname"
            :rules="[checkTextFeld('user_vorname')]"
            label="Vorname"
          ></v-text-field>
        </v-col>

        <v-col cols="12" md="5">
          <v-text-field
            v-model="userdata.user_name"
            :rules="[checkTextFeld('user_name')]"
            label="Nachname"
          ></v-text-field>
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="12" md="8">
          <v-text-field
            v-model="userdata.user_strasse"
            :rules="[checkTextFeld('user_strasse')]"
            label="Straße"
          ></v-text-field>
        </v-col>

        <v-col cols="12" md="4">
          <v-text-field
            v-model="userdata.user_hsnr"
            :rules="[checkTextFeld('user_hsnr')]"
            label="Hausnummer"
          ></v-text-field>
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="12" md="4">
          <v-text-field
            v-model="userdata.user_plz"
            :rules="[checkTextFeld('user_plz')]"
            label="PLZ"
          ></v-text-field>
        </v-col>

        <v-col cols="12" md="8">
          <v-text-field
            v-model="userdata.user_ort"
            :rules="[checkTextFeld('user_ort')]"
            label="Ort"
          ></v-text-field>
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="12" md="6">
          <v-text-field
            v-model="userdata.user_pass_neu1"
            :rules="[checkTextFeld('user_pass_neu1')]"
            label="Passwort"
            type="password"
          ></v-text-field>
        </v-col>

        <v-col cols="12" md="6">
          <v-text-field
            v-model="userdata.user_pass_neu2"
            :rules="[checkTextFeld('user_pass_neu2')]"
            label="Passwort wiederholen"
            type="password"
          ></v-text-field>
        </v-col>
      </v-row>

      <v-btn color="primary" @click="submitForm" style="font-size: 18px;" 
        >Jetzt kostenlos für TeamWaizmann anmelden</v-btn
      >
    </v-form>
      </v-card-text>
      </v-card>
    </div>
</template>

<script setup>
  import { ref } from "vue";
  import BaseCalls from "@/services/BaseCalls";
  // Importieren Sie Ihre Stores, falls sie extern definiert sind
  import { useErrorStore } from "@/stores/errorStore";
  import { useSuccessStore } from "@/stores/successStore";
  import { useUserStore } from "@/stores/user";
  import { useRouter } from "vue-router";

  const router = useRouter();
  const userStore = useUserStore();

  //   const userdata = ref({
  //     user_firma: "Meier Test",
  //     user_titel: "Herr",
  //     user_vorname: "Meier",
  //     user_name: "Meier",
  //     user_email: "mails738@gmail.com",
  //     user_strasse: "we",
  //     user_hsnr: "3",
  //     user_plz: "33333",
  //     user_ort: "wewe",
  //     user_pass_neu1: "florian",
  //     user_pass_neu2: "florian",
  //   });

  const userdata = ref({
    user_firma: "",
    user_titel: "",
    user_vorname: "",
    user_name: "",
    user_email: "",
    user_strasse: "",
    user_hsnr: "",
    user_plz: "",
    user_ort: "",
    user_pass_neu1: "",
    user_pass_neu2: "",
  });
  const form = ref(null);
  function checkFormular() {
    let fields = [
      "user_hsnr",
      "user_strasse",
      "user_plz",
      "user_ort",
      "user_name",
      "user_vorname",
      "user_email",
      "user_pass_neu1",
    ];

    // Stellen Sie sicher, dass antragdata.value existiert und korrekt strukturiert ist
    if (!userdata.value || typeof userdata.value !== "object") {
      console.error("Fehler: antragdata.value ist nicht korrekt definiert.");
      return false;
    }

    for (let i = 0; i < fields.length; i++) {
      const field = fields[i];
      let validateFunction = checkTextFeld(field);

      let errorField = validateFunction(userdata.value[field]);

      if (errorField !== true) {
        // Angenommen, checkTextFeld gibt 'true' zurück, wenn kein Fehler vorliegt
        console.log("Fehler im Feld: " + field + " - " + errorField);

        return false;
      }
    }

    // Alle Felder sind gültig

    return true;
  }

  const checkTextFeld = (feld) => {
    return (value) => {
      const trimValue = value && value.trim(); // trim einmal, verwende überall
      const errorMessages = {
        user_vorname: "Bitte geben Sie Ihren Vornamen an.",
        user_name: "Bitte geben Sie Ihren Nachnamen an.",
        user_ort: "Bitte geben Sie Ihren Ort an.",
        user_plz: "Bitte geben Sie Ihre PLZ an.",
        user_strasse: "Bitte geben Sie Ihre Straße an.",
        user_hsnr: "Bitte geben Sie Ihre Hausnummer an.",
        user_email: "Bitte geben Sie eine gültige E-Mail an.",
        user_pass_neu1: "Bitte geben Sie 2 identische Passwörter an.",
        user_pass_neu2: "Bitte wiederholen Sie Ihr Passwort.",
      };

      if (!trimValue) {
        return errorMessages[feld] || true;
      }
      if (feld == "user_email") {
        if (!validateEmail(trimValue)) {
          return errorMessages[feld] || true;
        }
      }

      if (feld == "user_pass_neu1") {
        if (userdata.value.user_pass_neu1 !== userdata.value.user_pass_neu2 && userdata.value.user_pass_neu2 !=  "") {
          console.log("error in passwort");
          return errorMessages[feld] || true;
        }
      }

      return true; // Kein Fehler gefunden
    };
  };
  function validateEmail(value) {
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!value) {
      console.log("error1 in email" + value);
      return false;
    } else if (!regex.test(value)) {
      console.log("error2 in email" + value);
      return false;
    }
    return true; // Kein Fehler
  }

  const errorStore = useErrorStore();
  const successStore = useSuccessStore();

  async function submitForm() {
    form.value.validate();
    if (!checkFormular()) {
      errorStore.setError(true, "Bitte beachten Sie die Fehler im Formular");
      console.log("error vn form");
      return false;
    }
    userdata.value.user_action = "insertUser"; // Korrigiert, um .value zu verwenden
    try {
      const resp = await BaseCalls.postUser(userdata.value); // Korrigiert, um .value zu verwenden
      if (resp.data && resp.data.success) {
        successStore.setSuccess(
          true,
          "Es hat geklappt",
          "Sie können  TeamWaizmann jetzt benutzen"
        );
        userStore.setUser(resp.data.user);
        userStore.isLogged = true;
        router.push("/");
      } else {
        errorStore.setError(true, resp.data.errortext);
      }
    } catch (err) {
      errorStore.setError(true, "Sie konnten nicht registriert werden");
    }
  }
</script>
