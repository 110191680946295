import { defineStore } from "pinia";
import { ref } from "vue"; // Import von ref aus Vue

export const useSharedDataStore = defineStore("sharedData", () => {
  // Initialisiere windowWidth mit einem Standardwert, falls window nicht verfügbar ist
  const windowWidth = ref(
    typeof window !== "undefined" ? window.innerWidth : 0
  );


  function getProduktText(bestell_produkt_texts) {
    // Prüfe, ob bestell_produkt_texts ein String ist
    if (typeof bestell_produkt_texts === 'string') {
      // Teile den String in ein Array auf, basierend auf dem Komma als Trennzeichen
      bestell_produkt_texts = bestell_produkt_texts.split(',').map(text => text.trim());
    }
  
    let result = '';
  
    for (let i = 0; i < bestell_produkt_texts.length; i++) {
      let text = bestell_produkt_texts[i].trim();
      let mappedText = '';
  
      switch (text) {
        case 'Waizmanntabelle Gesamtausgabe':
          mappedText = 'Gesamtausgabe';
          break;
        case 'Waizmanntabelle PZR':
          mappedText = 'Prophylaxe';
          break;
        case 'Waizmanntabelle ToGo K.':
        case 'Waizmanntabelle ToGo K':
          mappedText = 'Kinder';
          break;
        case 'Waizmanntabelle ToGo':
          mappedText = 'Erwachsene';
          break;
        case 'Aufsteller':
          continue;
        // default:
        //   mappedText = 'ToGo'; // Originaltext verwenden, wenn keine Zuordnung gefunden wird
      }
  
      if (mappedText) {
        if (result !== '') {
          result += ', ';
        }
        result += mappedText;
      }
    }
  
    return result;
  }

  function getTimeAgo(datevor) {
    const now = new Date();
    const date = new Date(datevor);
    const diff = now - date; // difference in milliseconds
  
    const diffMinutes = Math.floor(diff / 60000);
  
    if (diffMinutes < 60) {
      return `vor ${diffMinutes} Minuten`;
    } else if (diffMinutes < 1440) {
      const diffHours = Math.floor(diffMinutes / 60);
      if (diffHours == 1) {
        return `vor einer Stunde`;
      } else {
        return `vor ${diffHours} Stunden`;
      }
    } else {
      const diffDays = Math.floor(diffMinutes / 1440);
      if (diffDays == 1) {  
        return `vor einem Tag`;
      } else {
        return `vor ${diffDays} Tagen`;
      }
    }
  }// Die Funktionen, die du verwenden möchtest
  const isMobile = () => windowWidth.value < 780;
  const isIpadAndBelow = () => windowWidth.value < 1450;
  const showMenueRechts = () => windowWidth.value >= 1450;
  const isDesktop = () => windowWidth.value >= 770;

  // Gib die reaktiven Daten und Methoden zurück
  return {
    windowWidth,
    getTimeAgo,
    getProduktText,
    isMobile,
    isIpadAndBelow,
    isDesktop,
    showMenueRechts,

  };
});
